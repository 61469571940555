@import "../../assets/styles/mixin.scss";

.Header {
  width: 100%;
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #fff;
  box-shadow: 0 5px 10px #eee;
  transition-duration: 250ms;
  @media (max-width: 999px) {
    height: auto;
  }
}
.Home .Header {
  background-color: #faf5ff;
}

.Home .active {
  border-bottom: 1px solid #ebebeb;
}

.Header__inner {
  @include WidthLayout;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 999px) {
    display: block;
  }
}
