@import "../../../../assets/styles/mixin.scss";

.HomeProduct {
  @include WidthLayout;
  padding: 50px 0 0 0;
  margin: 0 auto;
}

.HomeProductLsit {
  display: flex;
  flex-wrap: wrap;
  margin: 80px 0 40px 0;
  @media (max-width: 999px) {
    display: block;
    margin: 50px 20px 40px;
  }
}

.HomeProductItem {
  width: 30%;
  min-height: 430px;
  flex: 1 1 auto;
  position: relative;
  margin: 0 1.5% 60px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  @media (max-width: 999px) {
    width: 100%;
    min-height: auto;
    margin: 0 0 60px;
  }
  &::after {
    width: 100%;
    height: 17px;
    position: absolute;
    left: 0;
    bottom: -12px;
    z-index: 1;
    background: url(../../../../assets/images/bg-circle.png) repeat;
    content: "";
  }

  strong {
    display: block;
    padding: 20px;
    color: #151e2e;
    font-size: 22px;
    font-weight: 600;
    line-height: 120%;
  }
  p {
    padding: 0 20px 16px;
    color: #868e96;
    font-size: 17px;
    line-height: 150%;
  }
  a {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%, 0);
    padding: 10px 20px;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 120%;
    border-radius: 5px;
    background-color: #0d6efd;
    @media (max-width: 999px) {
      display: inline-block;
      position: relative;
      left: 0;
      bottom: 0;
      transform: translate(0, 0);
      margin: 0 0 30px 0;
    }
  }
  span {
    width: 100%;
    height: 200px;
    display: block;

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px 6px 0 0;
      object-fit: cover;
    }
  }
}
