@mixin TitleBar($width) {
  width: $width;
  height: 4px;
  display: block;
  margin: 0 auto 20px;
  border-radius: 6px;
  background: #2d2ed4;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e24997),
    to(#2d2ed4)
  );
  background: linear-gradient(to right, #e24997, #2d2ed4);
  content: "";
}

@mixin WidthLayout {
  width: 1000px;
  @media (max-width: 999px) {
    width: 100%;
  }
}
