.Logo {
  width: 100%;
  margin: -8px 0 0 20px;

  @media (max-width: 999px) {
    width: 150px;
    margin: 20px auto 0;
  }
}

.Logo__title {
  a {
    width: 150px;
    height: 40px;
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
