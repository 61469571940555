* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

::before,
::after {
  box-sizing: inherit;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

body {
  background-color: #fff;
  text-size-adjust: none;
  word-break: keep-all;
  line-height: 1;
  text-rendering: optimizeLegibility;
  font-family: "Spoqa Han Sans", "Malgun Gothic", "맑은 고딕", helvetica,
    "Apple SD Gothic Neo", "Arial", sans-serif;
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

fieldset,
img {
  border: 0;
  vertical-align: top;
}

embed,
object,
iframe {
  border: 0;
  vertical-align: bottom;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

small {
  font-size: 80%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  caption {
    caption-side: top;
  }
}

blockquote,
q {
  quotes: none;
}

legend {
  color: inherit;
}

input,
select {
  vertical-align: middle;
}

li {
  list-style: none;
}

input,
textarea,
select,
button {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0;
  appearance: none;
  background-color: transparent;
}

input[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
