.LivingWell {
  position: relative;
  z-index: 1;
  padding: 100px 0;
  background-color: #faf5ff;
  @media (max-width: 999px) {
    padding: 50px 0;
  }
  &::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -10;
    background: url("../../../../assets/images/dot-gray.png") repeat;
    content: "";
  }
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    background: #ffffff;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#ffffff),
      color-stop(transparent),
      to(#ffffff)
    );
    background: linear-gradient(to right, #ffffff, transparent, #ffffff);
    content: "";
    @media (max-width: 999px) {
      display: none;
    }
  }
}

.LivingWell__description {
  display: flex;
  padding: 50px 20px 0;
  color: #7c8087;
  font-size: 17px;
  line-height: 180%;
  @media (max-width: 999px) {
    display: block;
    padding: 30px 20px 0;
    font-size: 14px;
  }
  strong {
    color: #343851;
  }

  p {
    flex-basis: 65%;
  }

  span {
    position: relative;
    flex-basis: 35%;
    margin: 0 0 0 40px;
    @media (max-width: 999px) {
      margin: 0 0 0 0;
      display: flex;
      justify-content: center;
    }

    img {
      height: 200px;
      position: relative;
      z-index: 10;
      margin: 80px 0 0 0;
      opacity: 1;
      content: "";
      @media (max-width: 999px) {
        display: none;
      }
    }
  }
}
