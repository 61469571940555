.ProductsItemsList__item {
  width: 30%;
  min-height: 430px;

  position: relative;
  margin: 0 1.5% 60px;

  @media (max-width: 999px) {
    min-height: auto;
    margin: 0 1.5% 30px;
    text-align: center;
  }
  strong {
    display: block;
    padding: 20px 0 10px;
    color: #151e2e;
    font-size: 22px;
    font-weight: 600;
    line-height: 130%;
    @media (max-width: 999px) {
      font-size: 14px;
    }
  }

  span {
    width: 100%;
    height: 300px;
    display: block;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.08);
    @media (max-width: 999px) {
      width: 100px;
      height: 130px;
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      object-fit: cover;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  p {
    width: 100%;
    padding: 5px;
    color: #868e96;
    font-size: 13px;
    font-weight: 700;
    line-height: 160%;

    @media (max-width: 999px) {
      display: none;
    }
  }
}
