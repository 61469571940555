.History {
  padding: 100px 0;
  @media (max-width: 999px) {
    padding: 50px 0;
  }
}

.HistoryTimeline {
  margin: 50px 0 0 0;
  @media (max-width: 999px) {
    margin: 30px 0 0 0;
  }
}

.HistoryTimeline__row {
  position: relative;
}

.HistoryTimeline__date {
  width: 140px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 60px 0 0;
  @media (max-width: 999px) {
    width: 110px;
  }
  &::before {
    width: 11px;
    height: 11px;
    position: absolute;
    right: 26px;
    top: 50%;
    z-index: 5;
    margin-top: -5.5px;
    border-radius: 50%;
    background-color: #f1c40f;
    content: "";
    @media (max-width: 999px) {
      right: 36px;
    }
  }
  &::after {
    width: 3px;
    height: 100%;
    position: absolute;
    right: 30px;
    top: 0;
    z-index: 1;
    background-color: #ebebeb;
    content: "";
    @media (max-width: 999px) {
      right: 40px;
    }
  }
  strong {
    color: #151e2e;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    @media (max-width: 999px) {
      font-size: 14px;
    }
  }
}

.HistoryTimeline__content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 150px;
  @media (max-width: 999px) {
    padding: 0 0 0 100px;
  }
}

.HistoryTimeline__box {
  width: 45%;
  min-height: 160px;
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  margin: 15px 2%;
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
  @media (max-width: 999px) {
    width: 90%;
    padding: 20px;
  }
  strong {
    display: block;
    padding: 5px 0 0 130px;
    color: #151e2e;
    font-size: 22px;
    font-weight: 700;
    line-height: 120%;
    @media (max-width: 999px) {
      padding: 100px 0 0 0;
      font-size: 17px;
      text-align: center;
    }
  }
  p {
    padding: 10px 0 0 130px;
    color: #7c8087;
    font-size: 17px;
    line-height: 150%;
    @media (max-width: 999px) {
      padding: 10px 0 0 0;
      font-size: 14px;
      text-align: center;
    }
    a {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 700;
      color: #070a57;
    }
  }
  span {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 30px;
    top: 30px;
    @media (max-width: 999px) {
      width: 80px;
      height: 80px;
      left: 50%;
      top: 20px;
      transform: translate(-50%, 0);
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
