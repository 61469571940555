.Values {
  padding: 100px 0;
  @media (max-width: 999px) {
    padding: 50px 0 20px 0;
  }
}

.Values__inner {
  display: flex;
  text-align: center;
  padding: 50px 0 0 0;
  @media (max-width: 999px) {
    display: block;
    padding: 30px 0 0 0;
  }
}

.Values__item {
  position: relative;
  padding: 0 20px;
  @media (max-width: 999px) {
    padding: 0 20px 30px;
  }

  strong {
    display: block;
    padding: 120px 0 0 0;
    color: #151e2e;
    font-size: 22px;
    font-weight: 700;
    line-height: 120%;
    @media (max-width: 999px) {
      padding: 90px 0 0 0;
      font-size: 20px;
    }
  }
  p {
    padding: 20px 0 0 0;
    color: #868e96;
    font-size: 17px;
    line-height: 150%;
    @media (max-width: 999px) {
      padding: 10px 0 0 0;
      font-size: 14px;
    }
    strong {
      color: #343851;
    }
  }
  span {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    margin: 0 0 30px 0;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    @media (max-width: 999px) {
      width: 70px;
      height: 70px;
    }
    img {
      width: 50px;
      height: 50px;
      margin: 20px auto 0;
      @media (max-width: 999px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
