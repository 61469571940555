@import "../../assets/styles/mixin.scss";

.SubTitle {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
  }

  .Introduce & {
    background-image: url("../../assets/images/sub-introduce.jpg");
    background-position: 50% 20%;
  }
  .Products & {
    background-image: url("../../assets/images/sub-products.jpeg");
  }
  .Inquiry & {
    background-image: url("../../assets/images/sub-inquiry.jpeg");
  }

  .SubTitle__inner {
    @include WidthLayout;
    margin: 0 auto;
    padding: 80px 0 20px 0;

    h2 {
      position: relative;
      z-index: 1;
      margin: 80px 0 0 0;
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.6px;
      text-align: center;
      @media (max-width: 999px) {
        margin: 128px 0 0 0;
        font-size: 30px;
      }
      &:after {
        @include TitleBar(30px);
        position: absolute;
        top: -10px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        margin: 0;
        background: #fff;
      }
    }
  }
}
