.Inquiry {
  .LayoutContents__sub {
    padding: 100px 0 0 0;
    @media (max-width: 999px) {
      padding: 50px 0 0 0;
    }
  }

  .Mixin__title-bar {
    &::before {
      margin: 0 0 10px;
    }
  }
}

.InquiryList {
  margin: 70px 0 0 0;
  @media (max-width: 999px) {
    margin: 40px 0 0 0;
  }
}
.InquiryItem {
  width: 100%;
  position: relative;
  margin: 55px 0 50px 0;
  @media (max-width: 999px) {
    margin: 35px 0 30px 0;
  }
}

.InquiryItem__title {
  position: relative;
  display: inline-block;
  margin: 6px 20px;
  color: #151e2e;
  font-size: 22px;
  font-weight: 700;
  line-height: 130%;
  @media (max-width: 999px) {
    font-size: 17px;
  }
  &:after {
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0;
    bottom: 4px;
    z-index: -1;
    background: #2d2ed4;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#e24997),
      to(#2d2ed4)
    );
    background: linear-gradient(to right, #e24997, #2d2ed4);
    opacity: 0.5;
    content: "";
    @media (max-width: 999px) {
      display: none;
    }
  }
}

.InquiryItem__answer {
  padding: 30px 20px 0;
  color: #868e96;
  font-size: 18px;
  line-height: 160%;
  @media (max-width: 999px) {
    padding: 20px 20px 0;
    font-size: 14px;
  }
}

.Inquiry__mail {
  margin: 80px 0 0;
  color: #151e2e;
  font-size: 18px;
  line-height: 160%;
  @media (max-width: 999px) {
    font-size: 14px;
  }
  strong {
    display: inline-block;
    margin: 0 0 20px 0;
  }
  p {
    margin: 0 0 0 20px;
    padding: 3px 20px 0 40px;
    background: url("../../assets/images/icon-mail.png") no-repeat;
    background-size: 30px 30px;
    @media (max-width: 999px) {
      padding: 0 20px 0 30px;
      background-size: 20px 20px;
    }
  }
  a {
    color: #070a57;
    font-weight: 700;
    text-decoration: underline;
  }
}
